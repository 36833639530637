import * as React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout title="404: Page Not Found">
    <h1 className='text-center mt-10'>404: Not Found</h1>
    <p className='text-center mb-20'>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
